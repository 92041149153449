import * as React from "react"
import {
  Text,
  Divider,
  Button,
  SimpleGrid,
  Grid,
  GridItem,
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
} from "@chakra-ui/react"
import jsPDF from "jspdf";
import '../assets/fonts/ipaexg-normal.js';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';


import {
  checkItems,
  checkCategories,
  Service,
} from 'config/const';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options: ChartOptions<'radar'> = {
  responsive: true,
  scales: {
    r: {
      beginAtZero: true,
    },
  },
};

type Props = {
  setPage: React.Dispatch<React.SetStateAction<string>>,
  setName: React.Dispatch<React.SetStateAction<string>>,
  setAge: React.Dispatch<React.SetStateAction<number | undefined>>,
  setDate: React.Dispatch<React.SetStateAction<string>>,
  setCheckKeys: React.Dispatch<React.SetStateAction<number[]>>,
  name: string,
  age?: number,
  date: string,
  checkKeys: number[],
  service: Service
};

type ResultLabels = string[];
type ResultData = number[];


export const Result: React.FC<Props> = (props) => {
  const [risk, setRisk] = React.useState(0); // 危険度
  const [urgency, setUrgency] = React.useState(''); // 緊急度

  const [resultLabels, setResultLabels] = React.useState<ResultLabels>([]);
  const [resultData, setResultData] = React.useState<ResultData>([]);

  const chartResult = {
    labels: resultLabels,
    datasets: [
      {
        label: '相続診断結果',
        data: resultData,
        backgroundColor: 'rgba(0, 105, 47, 0.2)',
        borderColor: 'rgba(0, 105, 47, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      scale: {
        ticks: {
          beginAtZero: true,
          min: 0,
        }
      }
    }
  };

  React.useEffect(() => {
    if (!props.age) {
      return;
    }

    if (props.age <= 39) {
      setUrgency('E');
    } else if (props.age >= 40 && props.age <= 50) {
      setUrgency('D');
    } else if (props.age >= 51 && props.age <= 60) {
      setUrgency('C');
    } else if (props.age >= 61 && props.age <= 70) {
      setUrgency('B');
    } else {
      setUrgency('A');
    }

    Object.keys(checkCategories).forEach(key => {
      checkCategories[key].score = 0;
    });


    let _risk = risk;
    props.checkKeys.forEach((key: number) => {
      if (key <= 5) {
        _risk += 60;
      } else {
        _risk += 15;
      }

      if (checkItems[key]) {
        const categoryKey = `category${String(checkItems[key].category)}`;
        if (checkCategories[categoryKey]) {
          if (categoryKey === 'category1') {
          }
          checkCategories[categoryKey].score += checkItems[key].score;
        }
      }
    });

    const _resultLabels = [] as ResultLabels;
    const _resultData = [] as ResultData;
    Object.keys(checkCategories).forEach(key => {
      _resultLabels.push(checkCategories[key].name);
      _resultData.push(Math.floor(Number(checkCategories[key].score) * 100 / checkCategories[key].total));
    });
    setResultLabels(_resultLabels);
    setResultData(_resultData);
    setRisk(_risk);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, props.age, props.date, props.checkKeys]);

  const onClickReturn = () => {
    props.setName('');
    props.setAge(undefined);
    props.setCheckKeys([]);
    props.setPage('index');
  };

  const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 297,
  };

  const onClickPdfDownload = () => {
    const doc = new jsPDF();
    doc.deletePage(1);

    doc.addPage();
    doc.addImage(
      "/assets/images/result/page1.jpg",
      "JPEG",
      0,
      0,
      210,
      297,
    );

    doc.setFont('ipaexg');
    doc.setTextColor(255, 0, 0)

    doc.setFontSize(30);
    doc.text(props.name, 25, 45);

    doc.setFontSize(30);
    doc.text(String(props.age), 116, 45);

    doc.setFontSize(13);
    doc.text(props.date.replaceAll('-', '/'), 163, 43.5);

    doc.setFontSize(15);
    props.checkKeys.forEach((key: number) => {
      doc.text('✓', 16, 67 + key * 4.85);
    });

    doc.setDrawColor(255, 0, 0);

    // 財産ライン座標 (138, 121, 121, 92)
    // 相続人ライン座標 (149, 121, 166, 92)
    // 遺産分割ライン座標 (155, 132, 188.5, 132)
    // その他ライン座標 (149, 142, 166, 171)
    // 相続対策ライン座標 (138, 142, 121, 171)
    // 相続税ライン座標 (132, 132, 98.5, 132)

    // 相続人
    let x0 = 149 + ((166 - 149) * resultData[0] / 100);
    let y0 = 111 - ((111 - 82) * resultData[0] / 100);

    // 遺産分割
    let x1 = 155 + ((188.5 - 155) * resultData[1] / 100);
    let y1 = 122;
    
    // その他
    let x5 = 149 + ((166 - 149) * resultData[5] / 100);
    let y5 = 132 + ((161 - 132) * resultData[5] / 100);

    // 相続対策
    let x4 = 138 - ((138 - 121) * resultData[4] / 100);
    let y4 = 132 + ((161 - 132) * resultData[4] / 100);

    // 相続税
    let x3 = 132 - ((132 - 98.5) * resultData[3] / 100);
    let y3  = 122;

    // 財産
    let x2 = 138 - ((138 - 121) * resultData[2] / 100);
    let y2 = 111 - ((111 - 82) * resultData[2] / 100);
    
    doc.line(x0, y0, x1, y1)
    doc.line(x1, y1, x5, y5)
    doc.line(x5, y5, x4, y4)
    doc.line(x4, y4, x3, y3)
    doc.line(x3, y3, x2, y2)
    doc.line(x2, y2, x0, y0)

    doc.setFontSize(30);
    doc.text((risk >= 100 ? '99*' : String(risk)), 145, 195);

    if (urgency === 'A') {
      doc.circle(145, 202, 4, 'S')
    }
    if (urgency === 'B') {
      doc.circle(154, 202, 4, 'S')
    }
    if (urgency === 'C') {
      doc.circle(163, 202, 4, 'S')
    }
    if (urgency === 'D') {
      doc.circle(172, 202, 4, 'S')
    }
    if (urgency === 'E') {
      doc.circle(181, 202, 4, 'S')
    }

    doc.setFontSize(8);
    doc.text(`※より詳しく知りたい方は${props.service.name}の担当者までご連絡下さい。`, 17, 267);

    doc.addImage(
      `/assets/images/logo/${props.service.key}_pc.png`,
      "PNG",
      50,
      272,
      37,
      10,
    );

    const pdfURL = doc.output("bloburl");
    window.open(pdfURL as any, "_blank");
  };

  return (
    <>
      <Text fontSize='2xl'as='b'>簡易相続診断システム</Text>
      <Divider />

      <SimpleGrid columns={{base: 1, md: 2}} spacing={10} mt={3}>
        <Box>
          <Text fontSize='2xl' color='green.800'>危険度ランク</Text>
          <Text fontSize='5xl' color='red.500' as='b'>{risk >= 100 ? '99*' : risk}</Text>
          <Text as='sup' ml={2}>点</Text>
          <Text fontSize='xs'>危険度ランクは高い得点ほど危険を表しています。</Text>

          <Divider my={5} />

          <Text fontSize='2xl' color='green.800'>緊急度ランク</Text>
          <Text fontSize='5xl' color='red.500' as='b'>{urgency}</Text>
          <Text as='sup' ml={2}>点</Text>
          <Text fontSize='xs'>緊急度ランクはAに近いほど緊急を表しています。</Text>
        </Box>

        <Box>
          <Radar data={chartResult} options={options} />
        </Box>
      </SimpleGrid>

      <Divider my={5} />

      <TableContainer mt={3}>
        <Table>
          <Tbody>
            <Tr>
              <Td colSpan={2} bg='green.800'><Text fontSize='sm' color='white'>危険度ランキング</Text></Td>
            </Tr>
            <Tr>
              <Td>80 点以上</Td>
              <Td>将来トラブルが発生する可能性が高いです</Td>
            </Tr>
            <Tr>
              <Td>50 点以上</Td>
              <Td>将来トラブルが発生する可能性があります</Td>
            </Tr>
            <Tr>
              <Td>50 点未満</Td>
              <Td>将来トラブルに発展するリスクは少ないでしょう</Td>
            </Tr>
            <Tr>
              <Td colSpan={2} bg='green.800'><Text fontSize='sm' color='white'>緊急度ランキング</Text></Td>
            </Tr>
            <Tr>
              <Td>A</Td>
              <Td>今すぐ専門家に相談することをお勧めします</Td>
            </Tr>
            <Tr>
              <Td>B</Td>
              <Td>早めに専門家に相談することをお勧めします</Td>
            </Tr>
            <Tr>
              <Td>C</Td>
              <Td>徐々に準備をはじめましょう</Td>
            </Tr>
            <Tr>
              <Td>D</Td>
              <Td>まずは準備すべきことを把握しましょう</Td>
            </Tr>
            <Tr>
              <Td>E</Td>
              <Td>今すぐ何かをする必要性は低いでしょう</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Grid mt={5} templateColumns="repeat(12, 1fr)" gap={5}>
        <GridItem colSpan={3}>
          <Button colorScheme='gray' size='lg' w='100%' onClick={onClickReturn}>戻る</Button>
        </GridItem>
        <GridItem colSpan={9}>
          <Button colorScheme='red' size='lg' w='100%' onClick={onClickPdfDownload}>診断結果をPDFでダウンロード</Button>
        </GridItem>
      </Grid>


      <Text color="red.600" mt={5}>※より詳しく知りたい方は{props.service.name}の担当者までご連絡下さい。</Text>

    </>
  );
};
