import * as React from "react"
import {
  ChakraProvider,
  VStack,
  Container,
  Show,
  Spacer,
  Link,
  theme,
} from "@chakra-ui/react"
import moment from 'moment';

import { Service, services } from 'config/const';
import { Index } from 'components/index';
import { Result } from 'components/result';

import logoPC from 'assets/images/logo/logo_jida_pc.png';
import logoSP from 'assets/images/logo/logo_jida_sp.png';

export const App = () => {
  const [service, setService] = React.useState<Service | undefined>();
  const [page, setPage] = React.useState('index');
  const [checkKeys, setCheckKeys] = React.useState<number[]>([]);
  const [name, setName] = React.useState('');
  const [age, setAge] = React.useState<number | undefined>(undefined);
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'));

  React.useEffect(() => {
    const href = window.location.href.replaceAll('http://', '').replaceAll('https://', '');
    const urls = href.split('.');
    if (services[urls[0]]) {
      setService(services[urls[0]]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <ChakraProvider theme={theme}>
      <VStack>
        <Container maxW='container.lg' bg='gray.50' display='flex' py={3}>
          <Show above='md'>
            <Link href='https://souzokushindan.com/' isExternal>
              <img src={logoPC} alt='簡易相続診断システム' />
            </Link>
          </Show>
          <Show below='md'>
            <Link href='https://souzokushindan.com/' isExternal>
              <img src={logoSP} alt='簡易相続診断システム' />
            </Link>
          </Show>
          <Spacer />
          {service &&
            <>
              <Show above='md'>
                <Link href={service.link} isExternal>
                  <img src={`/assets/images/logo/${service.key}_pc.png`} alt={service.name} />
                </Link>
              </Show>
              <Show below='md'>
                <Link href={service.link} isExternal>
                  <img src={`/assets/images/logo/${service.key}_sp.png`} alt={service.name} />
                </Link>
              </Show>
            </>
          }
        </Container>
        <Container maxW='container.lg' py={3}>
          {(page === 'index' && service) &&
            <Index
              setPage={setPage}
              setCheckKeys={setCheckKeys}
              setName={setName}
              setAge={setAge}
              setDate={setDate}
              checkKeys={checkKeys}
              name={name}
              age={age}
              date={date}
              service={service}
            />
          }
          {(page === 'result' && service) &&
            <Result
              setPage={setPage}
              setCheckKeys={setCheckKeys}
              setName={setName}
              setAge={setAge}
              setDate={setDate}
              checkKeys={checkKeys}
              name={name}
              age={age}
              date={date}
              service={service}
            />
          }
        </Container>
      </VStack>
    </ChakraProvider>
  );
};
