import * as React from "react"
import {
  Text,
  Alert,
  Divider,
  Card,
  CardHeader,
  CardBody,
  Heading,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Switch,
  FormControl,
  FormLabel,
  Button,
  SimpleGrid,
  Input,
  Box,
  Link,
} from "@chakra-ui/react"

import {
  Service,
  checkItems,
  Item,
} from 'config/const';

type Props = {
  setPage: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setAge: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  setCheckKeys: React.Dispatch<React.SetStateAction<number[]>>;
  name: string;
  age?: number;
  date: string;
  checkKeys: number[];
  service: Service
};

export const Index: React.FC<Props> = (props) => {
  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    if (props.name && props.name !== '' && props.age && props.age >= 18 && props.date && props.date !== '') {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, props.age, props.date]);

  const onItemClick = (key: number) => {
    let keys = props.checkKeys;
    var index = keys.indexOf(key);

    if (index > -1) {
      keys.splice(index, 1);
    } else {
      keys.push(key);
      keys.sort((a, b) => a - b);
    }

    props.setCheckKeys(keys);
  };
  
  const onExamResultClick = () => {
    if (!canSubmit) {
      return;
    }

    console.log(props.checkKeys)
    props.setPage('result');
  };

  return (
    <>
      <Text fontSize='2xl'as='b'>簡易相続診断システム</Text>
      <Divider />

      <Alert mt={3} bg='gray.100'>
        下記項目に相続診断を行いたい方の情報を入力またはチェックしてください。
      </Alert>

      <Card mt={3} border='2px' borderColor='green.600'>
        <CardHeader bg='green.600' color='white'>
          <Heading size='md'>診断情報</Heading>
        </CardHeader>
        <CardBody>
          <FormControl>
            <FormLabel>相続診断対象者のお名前 <Text as='sup' color='red'>*</Text></FormLabel>
            <Input type='text' placeholder='相続診断対象者のお名前' value={props.name} onChange={(e: any) => props.setName(e.target.value)} required />
          </FormControl>

          <SimpleGrid columns={{base: 1, md: 2}} spacing={{base: 0, md: 10}}>
            <FormControl mt={3}>
              <FormLabel>相続診断対象者の年齢 <Text as='sup' color='red'>*</Text></FormLabel>
              <Input type='number' placeholder='相続診断対象者の年齢' value={props.age} onChange={(e: any) => props.setAge(e.target.value)} required />
            </FormControl>

            <FormControl mt={3}>
              <FormLabel>相続診断チェックシート作成日 <Text as='sup' color='red'>*</Text></FormLabel>
              <Input type='date' placeholder='相続診断チェックシート作成日' value={props.date} onChange={(e: any) => props.setDate(e.target.value)} required />
            </FormControl>
          </SimpleGrid>
        </CardBody>

      </Card>

      <Alert mt={3} bg='gray.100'>
        下記の項目で該当する内容にチェックしてください。
      </Alert>

      <Card mt={3} border='2px' borderColor='green.600'>
        <CardHeader bg='green.600' color='white'>
          <Heading size='md'>チェック項目</Heading>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant='striped' colorScheme='gray'>
              <Tbody>
                {checkItems.map((item: Item, key: number) => {
                  return (
                    <Tr key={key}>
                      <Td>
                        <FormControl display='flex' alignItems='center'>
                          <FormLabel htmlFor={`item${key}`} mb={0} color='red.800' width='15px'>
                            {key + 1}
                          </FormLabel>
                          <Switch id={`item${key}`} colorScheme='green' onChange={() => onItemClick(key)} />
                          <FormLabel htmlFor={`item${key}`} mb={0} ml={3}>
                            {item.description}
                          </FormLabel>
                        </FormControl>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>

      </Card>

      <Button w='100%' mt={5} colorScheme='green' size='lg' type='button' onClick={onExamResultClick} isDisabled={!canSubmit}>診断する</Button>

      <Box display='flex' mt={5}>
        <Text>利用方法が解らない方は{props.service.name}の担当者までご連絡下さい。</Text>
      </Box>
    </>
  );
};
