export interface Service {
  key: string,
  name: string,
  link: string,
}

export type Services = { [key: string]: Service };

export const services: Services = {
  'demo': {
    key: 'demo',
    name: '御社名',
    link: 'https://souzokushindan.com'
  },
  'joyobank': {
    key: 'joyobank',
    name: '常陽銀行',
    link: 'https://www.joyobank.co.jp'
  }
}

export interface Item {
  description: string,
  category: number,
  score: number,
  danger: number,
  comment: string
}

type Items = Item[];

export const checkItems: Items = [
  {
    description: '相続人に長い間連絡が取れない人がいる',
    category: 1,
    score: 5,
    danger: 100,
    comment: '相続手続きが進まない可能性があります。大至急専門家に相談して下さい。'
  },
  {
    description: '相続人の仲が悪い',
    category: 2,
    score: 5,
    danger: 90,
    comment: '遺産分割が成立しない争族の可能性があります。遺言作成等をご検討下さい。'
  },
  {
    description: '子どもの間で親の介護に対する負担等に偏りがある',
    category: 2,
    score: 4,
    danger: 85,
    comment: '子どもの間で親の介護に対する負担等に偏りがある'
  },
  {
    description: '上場していない会社の株式を持っている',
    category: 4,
    score: 5,
    danger: 83,
    comment: '未上場株式は思ったより評価が高くなる場合がありますので、納税資金の確保にご注意下さい。'
  },
  {
    description: '分けることが難しい不動産や株式がある',
    category: 3,
    score: 5,
    danger: 80,
    comment: '遺産分割の方法、配分等について話し合っておいた方が良いでしょう。'
  },
  {
    description: '財産状況をあまり把握していない',
    category: 3,
    score: 4,
    danger: 75,
    comment: '相続手続きが滞る可能性がありますので、エンディングノートの作成をご検討下さい。'
  },
  {
    description: '一部の子どもや孫にだけお金をあげている',
    category: 2,
    score: 3,
    danger: 72,
    comment: '不公平が争族を生む可能性がありますのでご注意下さい。'
  },
  {
    description: '（企業経営者の場合）会社を継ぐ人が決まっていない',
    category: 6,
    score: 4,
    danger: 66,
    comment: '事業継続のため、早目に後継者問題に結論を出しましょう。'
  },
  {
    description: '先祖名義のままになっている土地がある',
    category: 3,
    score: 2,
    danger: 61,
    comment: '相続手続きが複雑になる可能性がありますので、今出来ることは処理してしまいましょう。'
  },
  {
    description: '家族名義で貯めているお金がある',
    category: 3,
    score: 3,
    danger: 59,
    comment: '相続財産の確定、税務調査での指摘等の懸念があります。早期に正しい姿へ戻しましょう。'
  },
  {
    description: '特定の相続人に多く財産を相続させたい',
    category: 2,
    score: 3,
    danger: 55,
    comment: '生前贈与、エンディングノート、遺言作成、生命保険の活用をご検討下さい。'
  },
  {
    description: '家族構成が複雑である（再婚しているなど）',
    category: 1,
    score: 2,
    danger: 53,
    comment: '先妻との間に子がいる場合、遺産分割が難航する場合があります。'
  },
  {
    description: '配偶者や子ども以外の人に財産を渡したい',
    category: 2,
    score: 3,
    danger: 51,
    comment: '生前贈与、遺言作成をご検討下さい。'
  },
  {
    description: '連帯保証人になっている',
    category: 6,
    score: 5,
    danger: 50,
    comment: '相続発生後、相続人が保証債務の履行を求められる可能性があります。'
  },
  {
    description: '相続人に手続きが難しい人がいる（未成年や障がい者など）',
    category: 1,
    score: 3,
    danger: 45,
    comment: '相続手続きのため、後見人・特別代理人等の選任が必要になる可能性があります。'
  },
  {
    description: '「借りている土地」や「貸している土地」がある',
    category: 4,
    score: 4,
    danger: 44,
    comment: '後々相続人が困らないよう、契約関係を整理し、相続人に分かるようにしておきましょう。'
  },
  {
    description: '相続人が「海外」や「遠い場所」にいる',
    category: 1,
    score: 2,
    danger: 42,
    comment: '相続手続きが煩雑になり、また遺産分割の話し合いが困難になる可能性があります。'
  },
  {
    description: '財産に不動産が多い',
    category: 4,
    score: 4,
    danger: 40,
    comment: '相続税がかかる場合、納税資金の確保にご注意下さい。'
  },
  {
    description: '借入やローンが多く残っている',
    category: 5,
    score: 4,
    danger: 35,
    comment: '債務を引き継ぐ相続人が継続して借金を返済できるよう資金繰りを確認しておきましょう。'
  },
  {
    description: '友人や知人にお金を貸している',
    category: 6,
    score: 4,
    danger: 33,
    comment: '将来「言った・言わない」とならないよう、貸し借りの事実を明確に残しましょう。'
  },
  {
    description: '誰にも相談しないで作った遺言書がある',
    category: 6,
    score: 2,
    danger: 31,
    comment: '法的に有効な遺言書なのか、財産の漏れはないのか等、専門家による確認をお勧めします。'
  },
  {
    description: '相続税がかかるのかまったく分からない',
    category: 5,
    score: 5,
    danger: 30,
    comment: '現状把握は相続対策の基本です。概算で構いませんので、一度試算することをお勧めします。'
  },
  {
    description: '誰も使っていない不動産がある',
    category: 3,
    score: 2,
    danger: 29,
    comment: '相続人にとって負の財産となる可能性がありますので、今の内に処分を含め、ご検討下さい。'
  },
  {
    description: '大きな保険金をもらう子どもや孫がいる',
    category: 5,
    score: 3,
    danger: 28,
    comment: '不公平が争族を生む可能性がありますのでご注意下さい。'
  },
  {
    description: '子どもがいない',
    category: 1,
    score: 3,
    danger: 25,
    comment: '配偶者がいても兄弟姉妹甥姪も相続人になりますので、遺言作成をご検討下さい。'
  },
  {
    description: 'なかなか入居者が決まらない古いアパートがある',
    category: 1,
    score: 0,
    danger: 16,
    comment: '相続人にとって負の財産とならないよう、将来どうすべきか、家族で話し合っておきましょう。'
  },
  {
    description: '誰にも相続について相談したことがない',
    category: 6,
    score: 4,
    danger: 15,
    comment: '相続対策は、相続人（家族）と情報を共有しておくことが重要です。'
  },
  {
    description: '子どもは皆自宅を持っている',
    category: 4,
    score: 2,
    danger: 10,
    comment: '自宅について小規模宅地等の評価減の特例が適用とならない可能性があります。'
  },
  {
    description: '古い書画や骨董を集めるのが好きだ',
    category: 4,
    score: 1,
    danger: 8,
    comment: '物によっては相続財産評価の算出に苦労し、また、思わぬ評価に驚く場合もあります。'
  },
  {
    description: '子どもが相続対策の相談に乗ってくれない',
    category: 5,
    score: 2,
    danger: 5,
    comment: '有益な相続対策を行うためには、親子のコミュニケーションが重要です。'
  }
];

export interface Category {
  name: string,
  total: number,
  score: number,
};

export type Categories = { [key: string]: Category };

export const checkCategories: Categories = {
  'category1': {
    'name': '相続人',
    'total': 15,
    'score': 0
  },
  'category2': {
    'name': '遺産分割',
    'total': 18,
    'score': 0
  },
  'category3': {
    'name': '相続財産',
    'total': 16,
    'score': 0
  },
  'category4': {
    'name': '相続税',
    'total': 16,
    'score': 0
  },
  'category5': {
    'name': '相続対策',
    'total': 16,
    'score': 0
  },
  'category6': {
    'name': 'その他',
    'total': 19,
    'score': 0
  }
};
